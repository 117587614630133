import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    once: {
        name: "",
        country: "",
        url: "",
        label: "",
        freq: "",
        grain: "",
    },
    pinned: [],
};
const pinSlice = createSlice({
    name: "tabs",
    initialState,
    reducers: {
        updateState: (state, action) => {
            console.log("Update State");
            state[action.payload.key] = action.payload.value;
        },
        updatePin: (state, action) => {
            if (state.pinned.length > 1) {
                return state;
            }
            state.pinned.push(action.payload);
        },
        removePin: (state, action) => {
            state.pinned = state.pinned.filter(
                (item) => (item?.name === action.payload?.name && item?.country !== action.payload?.country) || item?.name !== action.payload?.name
            );
        },
        resetValues: (state) => {
            console.log("Inside resetValues");
            state.pinned = state.pinned.filter(
                (item) => {
                    return item?.name === 'XyZ'
                }
            );
        }
    },
});
export const { updateState, updatePin, removePin, resetValues } = pinSlice.actions;
export default pinSlice;