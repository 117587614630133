import React, { useRef, useState, lazy, Suspense } from "react";
import o9Interface from "./o9Interface";
import { Loading } from "./components/Loader";

const WidgetMapper = lazy(() => import("./WidgetMapper"));

export default function App() {
  let status = useRef("initial");
  let [counter, setCounter] = useState(0);
  let userDataUpdated = useRef(false);
  let userArray = useRef([]);
  const [reportName, setReportName] = useState("");

  const registerApp = (eventObj) => {
    console.log("Child App received event: " + eventObj.eventType);
    //eventObj.eventType === "Load" || eventObj.eventType === "Reload" || eventObj.eventType === "Show" || eventObj.eventType === "Resize" || eventObj.eventType === "FilterChange"
    //Load type resize is for designer, favouites settings.
    //Load type show is for to show all combinations out of all filters.
    //if (eventObj.eventType === "Load" || eventObj.eventType === "Reload" || eventObj.eventType === "FilterChange" || eventObj.eventType === "Show" || eventObj.eventType === "Resize") {
    if (
      eventObj.eventType === "Load" ||
      eventObj.eventType === "Reload" ||
      eventObj.eventType === "FilterChange" ||
        eventObj.eventType === "Resize" ||
        eventObj.eventType ==="Show"
    ) {
      window.reportData = eventObj.eventData.reportDefinition;
      window.eventType = eventObj.eventType;

      //updating registration status and counter
      status.current = eventObj.eventType;
      setCounter((currCount) => currCount + 1);
      //counter.current = counter.current + 1;
      console.log("app locale values: ", counter, status);
      if (!userDataUpdated.current) {
        o9Interface.getLoggedInUser((result) => {
          window.theme = {
            tenantTheme: "o9black",
            userMode: "light",
          };
          if (result.response.Theme !== undefined) {
            window.theme.tenantTheme = result.response.Theme;
          }
          if (result.response.UserDefaultPref.Theme.Mode !== undefined) {
            window.theme.userMode = result.response.UserDefaultPref.Theme.Mode;
          }

          // user role processing
          let user = [];
          if (result.response.Name !== undefined) {
            user[0] = result.response.Name;
          }
          if (result.response.RoleNames.length > 0) {
            let temp = [];
            for (let i = 0; i < result.response.RoleNames.length; i++) {
              temp.push(result.response.RoleNames[i]);
            }
            user[1] = temp;
          }

          userDataUpdated.current = true;
          userArray.current = user;
          setReportName(window.reportData.Name);
        });
      }
    }
  };

  if (status.current === "initial") {
    console.log("Registering Custom Widget with LS");
    o9Interface.register(registerApp);
  }

  return (
    <Suspense fallback={<Loading />}>
      <WidgetMapper
        reportName={reportName}
        counter={counter}
        status={status.current}
        userArray={userArray.current}
      />
    </Suspense>
  );
}
