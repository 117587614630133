import { createSlice, current } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
    Name: "Feature.Engineered",
    Data: {},
    FeatureOpertaion: "",
    Expression: "",
    ArithmaticOperation: "",
    Constant: "",
    ListofFeatures:[],
};

export const featureDataSlice = createSlice({
    name: "featureData",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        changeFeatureName: (state, action) => {
            // console.log("name changed to : ",action.payload)
            state.Name = action.payload;
        },
        changeFeatureData: (state, action) => {
            state.Data = action.payload;
            console.log(current(state));
        },
        changeColumnName: (state, action) => {
            const FeatureData = current(state).Data;
            const { columnId, newTitle } = action.payload;
            let flag = false;

            for (let id in FeatureData.columnOrder) {
                let title = FeatureData.columns[FeatureData.columnOrder[id]].title;

                if (title == newTitle) {
                    flag = true;
                }
            }

            if (flag) {
                toast.error("Name already Exists");
            } else {
                // Create a new columns object with the updated title
                const newColumns = {
                    ...FeatureData.columns,
                    [columnId]: {
                        ...FeatureData.columns[columnId],
                        title: newTitle,
                    },
                };

                // Create a new FeatureData object with the updated columns object
                const newFeatureData = {
                    ...FeatureData,
                    columns: newColumns,
                };

                // Update the FeatureData state with the newFeatureData object
                console.log(newFeatureData);
                state.Data = newFeatureData;
            }
        },
        changeFeatureOperation: (state, action) => {
            state.FeatureOpertaion = action.payload;
        },
        changeExpression: (state, action) => {
            state.Expression = action.payload;
        },
        changeArithmaticOperation: (state, action) => {
            state.ArithmaticOperation = action.payload;
        },
        changeConstant: (state, action) => {
            state.Constant = action.payload;
        },
        changeListOfFeatures: (state, action) => {
            state.ListofFeatures = action.payload;
            console.log(current(state));
        },
    },
});

export const {
    changeFeatureName,
    changeFeatureData,
    changeColumnName,
    changeArithmaticOperation,
    changeConstant,
    changeFeatureOperation,
    changeExpression,
    changeListOfFeatures,
    } = featureDataSlice.actions;

export const selectFeatureName = (state) => state.featureData.Name;

export const selectFeatureData = (state) => state.featureData.Data;

export const selectFeatureOperation = (state) => state.featureData.FeatureOpertaion;

export const selectExpression = (state) => state.featureData.Expression;

export const selectArithmaticOperation = (state) => state.featureData.ArithmaticOperation;

export const selectConstant = (state) => state.featureData.Constant;

export const selectListOfFeatures = (state) => state.featureData.ListofFeatures;

export default featureDataSlice.reducer;
