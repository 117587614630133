import tabsSlice from "./slices/tabs";
import pinSlice from "./slices/pins";
import featureDataReducer from "./slices/FeatureDataSlice";
import ERDataReducer from "./slices/ERDataSlice";
import { configureStore } from "@reduxjs/toolkit";

const store = configureStore({
  reducer: {
        pins: pinSlice.reducer,
        featureData: featureDataReducer,
        ERData: ERDataReducer,
  },
  devTools: true,
});
export default store;
