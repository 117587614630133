import loadingImg from "./../data/loading-image.gif";

export const Loading = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
              height: "100vh",
              border: "None",
              boxShadow:"None"
      }}
    >
      <img src={loadingImg} alt="Loader" />
    </div>
  );
};