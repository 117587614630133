import { createSlice, current } from "@reduxjs/toolkit";


const initialState = {
    tables: {},
    edges: [],
    isExpanded: true
};

export const ERDataSlice = createSlice({
    name: "ERData",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        changeTables: (state, action) => {
            // console.log("name changed to : ",action.payload)
            state.tables = action.payload;
        },
        changeERData: (state, action) => {
            state = action.payload;
            console.log(current(state));
        },
        changeEdges: (state, action) => {
            state.edges = action.payload;
        },
        changeIsExpanded: (state, action) => {
            state.isExpanded = action.payload;
        }
    },
});

export const {
    changeTables,
    changeERData,
    changeEdges,
    changeIsExpanded
    } = ERDataSlice.actions;

export const selectTables = (state) => state.ERData.tables;

export const selectEdges = (state) => state.ERData.edges;

export const selectIsExpanded = (state) => state.ERData.isExpanded;

export default ERDataSlice.reducer;
